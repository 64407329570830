import { createRouter, createWebHistory } from "vue-router";
import { sso } from "roboflex-thalamus-sso-lib";

import InjetoraDashboard from "@/view/InjetoraDashboard.vue";
import PainelEdicao from "@/view/PainelEdicao.vue";
// import ApontamentoEdicao from "@/view/ApontamentoEdicao.vue";
import PlanejamentoInjecao from "@/view/PlanejamentoInjecao.vue"

export class RoutesName {
  static injetoraDashboard = "injetora-dashboard";
  static painelEdicao = "painel-edicao";
  static editarApontamento = "editar-apontamento";
  static planejamentoInjecao = "planejamento-injecao";
}

function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [
  {
    path: "/",
    name: RoutesName.injetoraDashboard,
    component: InjetoraDashboard,
    beforeEnter: guardMyroute,
  },
  {
    path: "/painel-edicao",
    name: RoutesName.painelEdicao,
    component: PainelEdicao,
    beforeEnter: guardMyroute,
  },
  // {
  //   path: "/editar-apontamento",
  //   name: RoutesName.editarApontamento,
  //   component: ApontamentoEdicao,
  //   beforeEnter: guardMyroute,
  // },
  {
    path: "/planejamento-injecao",
    name: RoutesName.planejamentoInjecao,
    component: PlanejamentoInjecao,
    beforeEnter: guardMyroute,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});

export default router;
