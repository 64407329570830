export class InjectionModel {
  constructor({
    opNum,
    productCode,
    productName,
    client,
    qtExpected,
    qtyDone,
    deliveryDate,
    scrap,
    residue,
    machineSeconds,
    machineHour,
    machineMin,
    downTimeSeconds,
    downTimeMin,
    downTimeHour,
    totalTimeMinutes,

    machineName,
    isPaused,
    lastUpdate,
    serviceType,

    
  }) {
    this.opNum = opNum;
    this.machineName = machineName;
    this.isPaused = isPaused;
    this.productCode = productCode;
    this.productName = productName;
    this.client = client;
    this.qtExpected = qtExpected;
    this.qtyDone = qtyDone;
    this.deliveryDate = deliveryDate;
    this.scrap = scrap;
    this.residue = residue;
    this.machineSeconds = machineSeconds;
    this.machineHour = machineHour;
    this.machineMin = machineMin;
    this.downTimeSeconds = downTimeSeconds;
    this.downTimeHour = downTimeHour;
    this.downTimeMin = downTimeMin;
    this.totalTimeMinutes = totalTimeMinutes;
    this.lastUpdate = lastUpdate;
    this.serviceType = serviceType;
  }

  static fromJson(json) {
    return new InjectionModel({
      opNum: json.opNum,
      isPaused: json.injetora_pausada == 1? true : false,
      machineName: json.maquina_nome,
      productCode: json.produto_cod,
      productName: json.produto_desc,
      qtExpected: json.qt,
      deliveryDate: json.dtPrevista,
      client: json.cliente_nome,

      scrap: json.qtRefugo ?? 0,
      residue: json.qtBorra ?? 0,
      qtyDone: json.qtProduzida != null ? parseInt(json.qtProduzida) : 0,

      downTimeSeconds: json.horasParada,
      downTimeHour: Math.floor(json.horasParada / 60),
      downTimeMin: json.horasParada % 60,

      machineSeconds: json.horasMaquina,
      machineHour: Math.floor(json.horasMaquina / 60),
      machineMin: json.horasMaquina % 60,

      totalTimeMinutes: json.horasTotal,

      lastUpdate: json.ultimoTrabalho_dtAtualizacao,
      serviceType: json.ultimoTrabalho_servico,
    });
  }
}
