import api from "@/services/api";
import { InjectionModel } from "../model/InjectionModel";

export class InjectionPanelDatasource {
  async getData() {
    const response = await api.get("injecao/informacoes");
    var dataList = [];

    for (var ele of response.data) {
      let injection = InjectionModel.fromJson(ele);
      // injection.machineName = `Injetora $[ele].slice(-1)}`
      injection.machineHour = Math.floor(injection.machineSeconds / 60);
      injection.machineMin = injection.machineSeconds % 60;
      dataList.push(injection);
    }

    return dataList;
  }
}
