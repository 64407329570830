<template>
  <section>
    <!-- <Picture></Picture> -->
    <div class="titulo">
      <div class="margem container">
        <h2>Planejamento de injeção</h2>
      </div>
    </div>
    <div class="margem">
      <div class="container bloco margem">
        <h3>OPs em aberto</h3>
        <div class="grid-4" @drop="onDrop($event, 'excluir')" @dragover.prevent @dragenter.prevent>
          <div v-for="op in ops" v-bind:key="op.id" class="bloco margem alinha-centro" draggable="true" @dragstart="dragStart($event, op)">
            {{ op.opNum }}
          </div>
        </div>
      </div>

      <!-- <th v-for="machine in machines" v-bind:key="machine.id">
                {{ machine.name }} {{ machine.orderNum }}
              </th> -->
      <div class="container bloco margem">
        <h3>Injetoras disponíveis</h3>

        <div class="grid-3">
          <div v-for="(machine, index) in machines" v-bind:key="machine.id" class="alinha-centro">
            <div>{{ machine.name }} {{ machine.orderNum }}</div>

            <div
              class="drop-zone"
              :class="{ 'drop-zone-hover': this.dropZoneHovered[index] }"
              @drop="onDrop($event, index)"
              @dragover.prevent
              @dragenter.prevent="onDragEnter(index)"
              @dragleave="onDragLeave(index)"
            >
              <div class="icone-add"></div>
            </div>

            <div v-for="subEle in dropZones[getKey(index)]" v-bind:key="subEle.id" class="bloco tile" draggable="true" @dragstart="dragStart($event, subEle)" style="margin-bottom: 15px !important">
              <div></div>
              OP {{ subEle.opNum }}
              <br />
              {{ subEle.productName }}
              <div class="icone-low-priority" @click="moveItemUp(dropZones[getKey(index)], dropZones[getKey(index)].indexOf(subEle))"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { EditarApontamentoDatasource } from "@/data/datasource/ApontamentoDatasource";
import Picture from "roboflex-vue-components-lib";

const aptDatasource = new EditarApontamentoDatasource();

export default {
  components: {
    Picture,
  },
  data() {
    return {
      machines: [],
      ops: [],
      dropZones: {
        listaUm: [],
        listaDois: [],
        listaTres: [],
      },
      dropHover: false,
      zoneKeys: ["listaUm", "listaDois", "listaTres"],
      dropZoneHovered: [false, false, false],
    };
  },

  async created() {
    this.machines = await aptDatasource.getMachines();
    this.ops = await aptDatasource.getOps();
  },

  methods: {
    dragStart(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.id);
    },
    async onDrop(event, listIndex) {
      this.dropZoneHovered[listIndex] = false;
      const itemID = event.dataTransfer.getData("itemID");
      var op = this.ops.find((item) => item.id == itemID);

      if (listIndex == "excluir") {
        this.findOpAndRemove(op);
        return;
      }

      /// Este index serve para ver se a OP já existe na lista de OP da injetora
      var itemIndex = this.dropZones[this.getKey(listIndex)].findIndex((ele) => ele.id == op.id);

      /// Caso exista, remove o item e retorna
      if (itemIndex > -1) {
        this.dropZones[this.getKey(listIndex)].splice(itemIndex, 1);
        return;
      }

      op.plannedForMachine = listIndex;

      op = await aptDatasource.getOpDetails(op.id);

      const key = this.getKey(listIndex);
      this.dropZones[key].push(op);
    },

    getKey(index) {
      return this.zoneKeys[index];
    },

    getOpOnMachine(machineIndex) {
      var opsOnMachine = [];
      for (var ele of this.ops) {
        if (ele.plannedForMachine == machineIndex) {
          opsOnMachine.push(ele);
        }
      }
      return opsOnMachine;
    },

    moveItemUp(list, index) {
      if (index > 0) {
        var item = list.splice(index, 1)[0];
        list.splice(index - 1, 0, item);
      }
    },

    findOpAndRemove(op) {
      for (var ele in this.dropZones) {
        var itemIndex = this.dropZones[ele].findIndex((opOnList) => opOnList.id == op.id);
        if (itemIndex > -1) {
          this.dropZones[ele].splice(itemIndex, 1);
        }
      }
    },

    onDragEnter(index) {
      this.dropZoneHovered[index] = true;
    },
    onDragLeave(index) {
      this.dropZoneHovered[index] = false;
    },
  },
};
</script>

<style scoped>
.drop-zone {
  height: 45px;
  border-radius: 12px;
  border: 1px var(--cor-primaria) solid;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.icone-add {
  transform: scale(1.3);
}

.tile {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
}

.icone-low-priority {
  transform: scale(1.5) rotate(180deg);
  cursor: pointer;
}

.drop-zone-hover {
  background-color: var(--cor-primaria-media);
}
</style>
