import { userLoggedIn } from "@/core/consts";
import api from "@/services/api";
import { PermissionModel } from "../model/PermissionModel";

export class PermissionDatasource {
    async getUserPermission(){
        const response = await api.get(`/usuario/${userLoggedIn.id}/funcionalidades`)
        console.log(response.data)
        return PermissionModel.fromJson(response.data)   
    }
}